import * as React from 'react';
import {Link, Avatar, Button, Container, TextField, Typography, Dialog, DialogContent, InputAdornment, IconButton, SwitchProps, Switch, styled, FormControlLabel} from '@mui/material';
import logo from '../../assets/img/logo.png';
import Box from "elements/Box";
import { ProjectConfig } from 'Global';
import axios from 'axios';
import { useSnackbar, withSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { resolve } from 'path';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ISwitch from 'elements/ISwitch';
import User from './Signup/User';
import Company from './Signup/Company';
import { last } from 'lodash';





export default function SignUp(props: any) {

    const [step, setStep] = useState("user");

    const defaultValues = {
      user: {
        email: '',
        password: '',
        name: '',
        lastname: '',
      },
      company: {
        name: '',
        phone: '',
        address: '',
        city_id: '',
        region_id: '',
        zip_code: '',
        industry: '',
      },
      store: {
        country_id: '',
        currency: '',
      }
    }

    const [formValues, setFormValues] = useState(defaultValues);	

    useEffect(() => {
      if(localStorage.getItem('ltkn')){
        window.location.href = '/webflow';
      } 
    }, []);

  return (
    <>
      <Box id='loginBox' className='intent-auth_component'>
        {step === "user" &&
          <User formValues={formValues} setFormValues={setFormValues} setStep={setStep} />
        }

        {step === "company" &&
          <Company formValues={formValues} setFormValues={setFormValues} setStep={setStep} />
        }
      </Box>
    </>
  );
}