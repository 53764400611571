import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Button, Chip } from '@mui/material';
import ISwitch from 'elements/ISwitch';
import { ApiRequest } from 'GlobalFunctions';
import { useSnackbar } from "notistack";
import { FaLink } from "react-icons/fa";
import { ProjectConfig } from 'Global';

import { CiCircleCheck } from "react-icons/ci";
import { CiCircleRemove } from "react-icons/ci";
import { CiCircleMore } from "react-icons/ci";
import { BsSend } from "react-icons/bs";
import { LoadingButton } from '@mui/lab';
import { width } from '@mui/system';
import { MdOutlineContentCopy } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";



const defaultValues: any = {
    default_mode: true,
    name: "Store",
    from: {
        active: true,
        email: "",
        status: "Not Verified",
    },
    dns: {
        active: false,
        domain: "",
        email: "",
        status: "Not Verified",
        VerificationToken: "",
    },
    smtp: {
        active: false,
        email: "",
        host: "",
        port: "",
        username: "",
        password: "",
        secure: false,
    },
};

export default function Email(props: any) {
    const { enqueueSnackbar } = useSnackbar();
    const [formValues, setFormValues] = useState<any>(defaultValues);
    const [selectedMode, setSelectedMode] = useState<string>('from');
    const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false);
    const [isDNSDisabled, setIsDNSDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showUpdateEmailButton, setShowUpdateEmailButton] = useState<boolean>(false);

    const [testEmail, setTestEmail] = useState<string>('');

    useEffect(() => {
        getEmailSettings();
    }, []);

    /*=============================================
    GET EMAIL SETTINGS
    =============================================*/
    const getEmailSettings = () => {
        ApiRequest({
            url: '/stores/getEmailSettings',
            method: 'GET',
            setResponse: (response: any) => {
                if (response.status === "success") {
                    const settings = response.data;
                    const activeMode = Object.keys(settings).find(
                        (key) => key !== 'default_mode' && settings[key]?.active
                    );
                    if (activeMode) {
                        setSelectedMode(activeMode);
                    }
                    setFormValues(settings);

                    if (settings.dns.email !== "") {
                        setShowUpdateEmailButton(true);
                    }

                    if (settings.from.email) {
                        setIsEmailDisabled(true);
                    }
                    if (settings.dns.domain) {
                        setIsDNSDisabled(true);
                    }
                }
            }
        });
    };

    /*=============================================
    METHOD CHANGE
    =============================================*/
    const handleMethodChange = (event: any) => {
        setSelectedMode(event.target.value);
        const prevSettings = { ...formValues };
        Object.keys(prevSettings).forEach((key) => {
            // Skip if the property is not an object or is default_mode
            if (typeof prevSettings[key] === 'object' && key !== 'default_mode') {
                prevSettings[key].active = false;
            }
        });
        const updatedSettings = {
            ...prevSettings,
            [event.target.value]: {
                ...prevSettings[event.target.value],
                active: true
            }
        };
        setFormValues(updatedSettings);
    };

    /*=============================================
    FIELD CHANGE
    =============================================*/
    const handleFieldChange = (event: any, field: string) => {
        if (selectedMode) {
            setFormValues({
                ...formValues,
                [selectedMode]: {
                    ...formValues[selectedMode],
                    [field]: event.target.value
                }
            });
        }
    };

    /*=============================================
    HANDLE SUBMIT
    =============================================*/
    const handleSubmit = async () => {
        ApiRequest({
            url: '/stores/updateEmailSettings',
            method: 'POST',
            body: {
                email_settings: formValues
            },
            setResponse: (response: any) => {
                if (response.status === "success") {
                    enqueueSnackbar('Email settings updated successfully', { variant: 'success' });

                    setFormValues(response.email_settings)
                    if (formValues.from.email) {
                        setIsEmailDisabled(true);
                    }
                    if (formValues.dns.domain) {
                        setIsDNSDisabled(true);
                    }
                } else {
                    enqueueSnackbar('An error occurred while updating email settings', { variant: 'error' });
                }
            }
        });
    };

    /*=============================================
    CLEAR EMAIL FIELD
    =============================================*/
    const clearEmailField = () => {
        setFormValues({
            ...formValues,
            from: {
                ...formValues.from,
                email: "",
                status: "Not Verified"
            }
        });
        setIsEmailDisabled(false);
    };

    const clearDNSField = () => {
        setFormValues({
            ...formValues,
            dns: {
                ...formValues.dns,
                domain: "",
                status: "Not Verified"
            }
        });
        setIsDNSDisabled(false);
    };

    /*=============================================
    SEND TEST EMAIL
    =============================================*/
    const sendTestEmail = async () => {
        setLoading(true);
        await ApiRequest({
            url: '/messaging/sendTestEmail',
            method: 'POST',
            body: {
                store_id: localStorage.getItem('store_id'),
                email: testEmail
            },
            setResponse: (response: any) => {
                if (response.status === "success") {
                    enqueueSnackbar('Test email sent successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar('Failed to send test email', { variant: 'error' });
                }
            }
        });
        setLoading(false);

    };


    const updateDNSEmail = async () => {

        if (formValues.dns.email === "") {
            enqueueSnackbar('Email cannot be empty', { variant: 'error' });
            return;
        }
        ApiRequest({
            url: '/stores/updateDNSEmail',
            method: 'POST',
            body: {
                email: formValues.dns.email,
            },
            setResponse: (response: any) => {
                if (response.status === "success") {
                    enqueueSnackbar('Email updated successfully', { variant: 'success' });
                } else {
                    enqueueSnackbar('Failed to update email', { variant: 'error' });
                }
            }
        });
    }

    return (
        <div className="account_section">
            <div className="section_header">
                <h2>Advanced Email</h2>
                <div className="section_actions">
                </div>
            </div>
            <div className="account_row">
                <span className="account_label">Default Mode</span>
                <ISwitch
                    checked={formValues.default_mode}
                    onChange={(e: any) => 
                        setFormValues({ 
                            ...formValues, 
                            default_mode: e.target.checked
                        })
                    }
                />
            </div>

            <div className="account_row">
                <span className="account_label">Name</span>
                <TextField
                    size="small"
                    value={formValues.name}
                    onChange={(e) => 
                        setFormValues({
                            ...formValues,
                            name: e.target.value
                        })
                    }
                    fullWidth
                    margin="normal"
                />
            </div>
            

            {!formValues.default_mode && (
            <>
                <div className="account_row">
                    <span className="account_label">Mode</span>
                    <FormControl fullWidth margin="normal" disabled={formValues.default_mode}>
                        <InputLabel>Select an email mode</InputLabel>
                        <Select
                            value={selectedMode}
                            onChange={handleMethodChange}
                            label="Select Payment Method"
                            size="small"
                        >
                            <MenuItem value="from">From Change</MenuItem>
                            <MenuItem value="dns">DNS</MenuItem>
                            <MenuItem value="smtp">SMTP</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            
                {selectedMode === 'from' && (
                    <>
                        <div className="account_row">
                            <span className="account_label">Email</span>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '10px' }}>
                                <TextField
                                    size="small"
                                    value={formValues.from.email}
                                    onChange={(e) => handleFieldChange(e, 'email')}
                                    fullWidth
                                    margin="normal"
                                    disabled={isEmailDisabled}
                                    InputProps={{
                                        endAdornment: isEmailDisabled && (
                                            <Button onClick={clearEmailField}>X</Button>
                                        )
                                    }}
                                />
                                {isEmailDisabled &&
                                    <Chip
                                    color={formValues.from.status === "Success" ? "primary" : formValues.from.status === "Not Verified" ? "secondary" : "default"}
                                    icon={
                                        formValues.from.status === "Verified" ? <CiCircleCheck /> :
                                        formValues.from.status === "Not Verified" ? <CiCircleRemove /> :
                                        <CiCircleMore />
                                    }
                                    label={formValues.from.status === "Success" ? "Verified" : "Not Verified"}
                                    style={{ marginTop: '10px' }}
                                    />
                                }
                            </div>
                            
                        </div>
                    </>
                )}

                {selectedMode === 'dns' && (
                    <>
                        <div className="account_row">
                            <span className="account_label">Domain</span>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '10px' }}>
                                <TextField
                                    size="small"
                                    value={formValues.dns.domain}
                                    onChange={(e) => handleFieldChange(e, 'domain')}
                                    fullWidth
                                    margin="normal"
                                    disabled={isDNSDisabled}
                                    InputProps={{
                                        endAdornment: isDNSDisabled && (
                                            <IoCloseOutline
                                                style={{ cursor: 'pointer',color: '#7963ff' }}
                                                onClick={clearDNSField}
                                            />
                                        )
                                    }}
                                />
                                {isDNSDisabled &&
                                    <Chip
                                        color={formValues.dns.status === "Success" ? "primary" : formValues.dns.status === "Not Verified" ? "secondary" : "default"}
                                        icon={
                                            formValues.dns.status === "Verified" ? <CiCircleCheck /> :
                                            formValues.dns.status === "Not Verified" ? <CiCircleRemove /> :
                                            <CiCircleMore />
                                        }
                                        label={formValues.dns.status === "Success" ? "Verified" : "Not Verified"}
                                        style={{ marginTop: '10px' }}
                                    />
                                }
                            </div>
                        </div>

                        <div className="account_row">
                            <span className="account_label">Email</span>
                            <div className='input_row dns_email'>
                                <TextField
                                    size="small"
                                    className='dns_email_input'
                                    value={formValues.dns.email}
                                    onChange={(e) => handleFieldChange(e, 'email')}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: <span style={{ color: '#666' }}>@{formValues.dns.domain}</span>
                                    }}
                                />
                                {showUpdateEmailButton && 
                                    <Button
                                        className='dns_email_button'
                                        variant="outlined"
                                        color="primary"
                                        size='small'
                                        style={{ whiteSpace: 'nowrap' }}
                                        onClick={() => {
                                            updateDNSEmail();
                                        }}
                                    >
                                        Update Email
                                    </Button>
                                }
                            </div>
                        </div>

                        {isDNSDisabled && formValues.dns.VerificationToken && formValues.dns.status !== "Success" && (
                            <div className="account_row">
                                <span className="account_label"></span>
                                <div className='account_dns_records'>
                                    <p className='account_description_text'>Please add the following TXT record to your DNS settings:</p>
                                    <div className='input_row'>
                                        <TextField
                                            fullWidth
                                            label="Host"
                                            value={formValues.dns.domain}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <MdOutlineContentCopy
                                                        style={{ cursor: 'pointer',color: '#7963ff' }}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(formValues.dns.domain);
                                                            enqueueSnackbar('Host copied to clipboard', { variant: 'success' });
                                                        }}
                                                    />
                                                ),
                                            }}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Value"
                                            value={formValues.dns.VerificationToken}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <MdOutlineContentCopy
                                                        style={{ cursor: 'pointer',color: '#7963ff' }}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(formValues.dns.VerificationToken);
                                                            enqueueSnackbar('Value copied to clipboard', { variant: 'success' });
                                                        }}
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )} 
                    </>
                )}
                
                {selectedMode === 'smtp' && (
                    <>
                        <div className="account_row">
                            <span className="account_label">Email</span>
                            <TextField
                                size="small"
                                value={formValues.smtp.email}
                                onChange={(e) => handleFieldChange(e, 'email')}
                                fullWidth
                                margin="normal"
                            />
                        </div>

                        <div className="account_row">
                            <span className="account_label">Host</span>
                            <TextField
                                size="small"
                                value={formValues.smtp.host}
                                onChange={(e) => handleFieldChange(e, 'host')}
                                fullWidth
                                margin="normal"
                            />
                        </div>

                        <div className="account_row">
                            <span className="account_label">Port</span>
                            <TextField
                                size="small"
                                value={formValues.smtp.port}
                                onChange={(e) => handleFieldChange(e, 'port')}
                                fullWidth
                                margin="normal"
                            />
                        </div>

                        <div className="account_row">
                            <span className="account_label">Secure</span>
                            <ISwitch
                                checked={formValues.smtp.secure}
                                onChange={(e: any) => 
                                    setFormValues({
                                        ...formValues,
                                        smtp: {
                                            ...formValues.smtp,
                                            secure: e.target.checked
                                        }
                                    })
                                }
                            />
                        </div>
                        
                        <div className="account_row">
                            <span className="account_label">Username</span>
                            <TextField
                                size="small"
                                value={formValues.smtp.username}
                                onChange={(e) => handleFieldChange(e, 'username')}
                                fullWidth
                                margin="normal"
                            />
                        </div>

                        <div className="account_row">
                            <span className="account_label">Password</span>
                            <TextField
                                size="small"
                                value={formValues.smtp.password}
                                onChange={(e) => handleFieldChange(e, 'password')}
                                fullWidth
                                margin="normal"
                            />
                        </div>

                        <div className="account_row">
                            <span className="account_label"></span>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%', justifyContent: 'center' }}>
                                <TextField 
                                    name="test_email"
                                    label="Test Email"
                                    variant="outlined"
                                    value={testEmail}
                                    onChange={(e) => setTestEmail(e.target.value)}
                                    margin="normal"
                                    fullWidth
                                />
                                
                                <LoadingButton
                                    variant="outlined"
                                    color="primary"
                                    style={{ whiteSpace: 'nowrap', padding: '10px 20px' }}
                                    loading={loading}
                                    onClick={sendTestEmail}
                                    startIcon={<BsSend />}
                                >
                                    Test SMTP
                                </LoadingButton>
                            </div>
                        </div>
                    </>
                )}
            </>
            )}
            <div className="account_row">
                <span className="account_label"></span>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '20px' }}
                    onClick={handleSubmit}
                >
                    Update
                </Button>
            </div>
        </div>
    );
}
