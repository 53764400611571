import { useState } from "react";
import Box from "elements/Box";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";

export default function Sites(props: any) {
    const { 
        sites,
        siteSelected,
        setSiteSelected,
        updateSiteId
    } = props;

    const [search, setSearch] = useState('');
    const filteredSites = sites.filter((site: any) => 
        site.displayName.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <>
            <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                sx={{ width: '100%', marginBottom: '20px' }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            
            <div className="webflow_sites_images">
                {filteredSites.map((site: any, index: number) => {
                    return (
                        <div 
                            className={`webflow_site_image ${siteSelected?.id === site.id ? 'site_selected' : ''}`} 
                            key={index} 
                            onClick={() => setSiteSelected(site)}
                        >
                            <img src={site.previewUrl} alt={site.displayName} />
                            <h3>{site.displayName}</h3>
                        </div>
                    )
                })}
            </div>

            <LoadingButton
                size="small"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ fontSize: '13px', padding: '10px 0px'}}
                onClick={() => {
                    updateSiteId()
                }}
                disabled={siteSelected === null}
                
            >
                Continue
            </LoadingButton>
        </>
    )
}
