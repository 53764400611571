import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { ApiRequest } from "GlobalFunctions";
import query from "devextreme/data/query";
import { useSnackbar, withSnackbar } from 'notistack';
import AutocompleteField from "elements/AutocompleteField";

export default function Company(props: any) {
    const { formValues, setFormValues, setStep } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [fieldsCompleted, setFieldsCompleted] = useState(false);
    const checkFields = () => {
        let valid = true;
        if(formValues.company.name === '') valid = false;
        if(formValues.company.phone === '') valid = false;
        if(formValues.company.address === '') valid = false;
        if(formValues.company.city_id === '') valid = false;
        if(formValues.company.zip_code === '') valid = false;
        if(formValues.company.industry === '') valid = false;
        if(formValues.store.country_id === '') valid = false;
        if(formValues.company.region_id === '') valid = false;
        if(formValues.store.currency === '') valid = false

        setFieldsCompleted(valid);
    }


    const submit = async () => {
        await updateStore();
    }

    const updateStore = async () => {

        await ApiRequest({
            url: '/stores/updateStore',
            method: 'POST',
            body: formValues,
            setResponse: (response: any) => {
                localStorage.setItem('ltkn', response.ltkn);
                localStorage.setItem('role_id', response.role_id);

                window.location.href = '/webflow?new_store';
                
            }
        });
    }

    /*=============================================
    GET COUNTRIES
    =============================================*/
    const [countries, setCountries] = useState([]);
    const getCountries = async () => {
        await ApiRequest({
            url: '/countries/getCountries',
            method: 'GET',
            setResponse: (response: any) => {
                setCountries(response);
            }
        });
    }

    

    /*=============================================
    GET INDUSTRIES
    =============================================*/
    const [industries, setIndustries] = useState([]);
    const getIndustries = async () => {
        await ApiRequest({
            url: '/industries/list',
            method: 'GET',
            setResponse: (response: any) => {
                setIndustries(response);
            }
        });
    }
    
    
    useEffect(() => {
        getCountries();
        getIndustries();
    }, []);
    

    useEffect(() => {
        checkFields();
    }, [formValues]);


    

    
    return (
        <div className="input_group">
            <h1 className="intent-auth_heading text-color-darkpurple">Quick Set Up</h1>


            <TextField 
                label="Company Name"
                value={formValues.company.name}
                onChange={(e) => setFormValues({...formValues, company: { ...formValues.company, name: e.target.value }})}
                variant="outlined"
                fullWidth
            />

            <div className="input_row">
                <TextField
                    label="Name"
                    value={formValues.user.name}
                    onChange={(e) => setFormValues({...formValues, user: { ...formValues.user, name: e.target.value }})}
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    label="Last Name"
                    value={formValues.user.lastname}
                    onChange={(e) => setFormValues({...formValues, user: { ...formValues.user, lastname: e.target.value }})}
                    variant="outlined"
                    fullWidth
                />
            </div>
            
            <TextField 
                label="Phone Number"
                value={formValues.company.phone}
                onChange={(e) => setFormValues({...formValues, company: { ...formValues.company, phone: e.target.value }})}
                variant="outlined"
                fullWidth
            />

            <div className="input_row">
                <FormControl fullWidth>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                        labelId="country-label"
                        id="country"
                        value={formValues.store.country_id}
                        label="Country"
                        name="country"
                        onChange={(e) => {
                            setFormValues({
                                ...formValues, 
                                store: { ...formValues.store, country_id: e.target.value },
                                company: { ...formValues.company, region_id: '', city_id: ''},
                            })
                        }}
                    >
                        {countries.map((country: any, index: number) => {
                            return (
                                <MenuItem key={index} value={country.country_id}>{country.country_name}</MenuItem>
                            ) 
                        })}
                        
                    </Select>
                </FormControl>
                
            

            
                {formValues.store.country_id !== '' && (
                <AutocompleteField
                    label="State"
                    endpoint="/countries/getRegions"
                    endpoint_params={{ country_id: formValues.store.country_id }}
                    value={formValues.company.region_id}
                    onChange={(data: any) => {
                        setFormValues({...formValues, 
                            company: { 
                                ...formValues.company, 
                                region_id: data ? data.value : '',
                                city_id: ''
                            }})
                    }}
                    fullWidth
                />
                )}
                
                </div>

                {formValues.company.region_id !== '' && (

                    <AutocompleteField
                        label="City"
                        endpoint="/shipping/zones/getCities"
                        endpoint_params={{ region_id: formValues.company.region_id }}
                        max_results={10}
                        dynamic={true}
                        value={formValues.company.city_id}
                        onChange={(value: any) => {
                            setFormValues({...formValues, company: { ...formValues.company, city_id: value ? value.value : '' }})
                        }}
                        clearOnSelect={true}
                    />
                )}
           

            

            <TextField 
                label="Address"
                value={formValues.company.address}
                onChange={(e) => setFormValues({...formValues, company: { ...formValues.company, address: e.target.value }})}
                variant="outlined"
                fullWidth
            />

            

            <TextField 
                label="Zip Code"
                value={formValues.company.zip_code}
                onChange={(e) => setFormValues({...formValues, company: { ...formValues.company, zip_code: e.target.value }})}
                variant="outlined"
                fullWidth
            />

            <FormControl fullWidth>
                <InputLabel id="currency-label">Currency</InputLabel>
                <Select
                    labelId="currency-label"
                    id="currency"
                    value={formValues.store.currency}
                    label="Currency"
                    name="currency"
                    onChange={(e) => {
                        setFormValues({...formValues, store: { ...formValues.store, currency: e.target.value }})
                    }}
                >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="CAD">CAD</MenuItem>
                </Select>
            </FormControl>

            <AutocompleteField
                label="Industry"
                endpoint="/industries/list"
                value={formValues.company.industry}
                onChange={(data: any) => {
                    setFormValues({...formValues, company: { ...formValues.company, industry: data ? data.value : '' }})
                }}
                fullWidth
            />
            
           
            <LoadingButton
                size="small"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ fontSize: '13px', padding: '10px 0px'}}
                onClick={() => {
                    submit()
                }}
                disabled={!fieldsCompleted}
            >
                Continue
            </LoadingButton>
            

        </div>
    )
}
