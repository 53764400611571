import { Button, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { ProjectConfig } from "Global";
import { ApiRequest } from "GlobalFunctions";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from "notistack";
import AlertDialog from "elements/AlertDialog";
import ShippingZonesEditor from "./ShippingZonesEditor";
import Flag from 'react-country-flag';
import LanguageIcon from '@mui/icons-material/Language';

export default function ShippingZonesList(props: any) {
    const { setLoading } = props;

    const [openEditor, setOpenEditor] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [activeItem, setActiveItem] = useState<any>(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [list, setList] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
        total_pages: 1,
        total_items: 0,
    });

    const currentPage = parseInt(localStorage.getItem('page') || "1");
    const [page, setPage] = useState(currentPage);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);
        loadList();
    }, [page]);

    /*=========================================================
    LOAD LIST
    =========================================================*/
    const loadList = async () => {
        setLoading(true);
        await ApiRequest({
            method: 'GET',
            url: '/shipping/zones/list',
            setResponse: (response: any) => {
                setList(response.list);
                setPagination({
                    total_pages: response.total_pages,
                    total_items: response.list.length,
                });
                setLoading(false);
            }
        })
    }

    /*=========================================================
    HANDLE MENU OPEN
    =========================================================*/
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, item: any) => {
        setMenuAnchor(event.currentTarget);
        setActiveItem(item);
    };

    /*=========================================================
    HANDLE MENU CLOSE
    =========================================================*/
    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    /*=========================================================
    DELETE ITEM
    =========================================================*/
    const handleDelete = (shipping_zone_id: number) => {
        setActiveItem(shipping_zone_id);
        setOpenAlert(true);
    }

    const deleteItem = () => {
        const body = { shipping_zone_id: activeItem };
        axios.post(ProjectConfig.api_url + "/shipping/zones/deleteItem", body)
            .then((response) => {
                if (response.data.status === "success") {
                    enqueueSnackbar('Item deleted successfully', { variant: 'success' });
                    loadList();
                }
            })
            .catch((err) => {
                console.log(err.data);
            })
            .then(() => {
                setOpenAlert(false);
            });
    }

    return (
        <div className='ModuleContainer'>
            <div className="ModuleHeader">
                <div className="ModuleTitle">
                    <h1>Shipping Zones</h1>
                </div>
                <div className="ModuleActions">
                    <Button
                        variant="outlined"
                        color="info"
                        onClick={() => {
                            setActiveItem(null);
                            setOpenEditor(true);
                        }}
                        className="btnList"
                        startIcon={<AddIcon />}
                    >
                        Add New Shipping Zone
                    </Button>
                </div>
            </div>

            <div className='ModuleContent'>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Zone</TableCell>
                            <TableCell>Rate Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((item, i) => {
                            // Aggregate all unique countries and regions across all zones
                            const allCountries = Array.from(new Set(item.zones.flatMap((zone: any) => zone.country_name.split(',').map((c: any) => c.trim()))));
                            const allRegions = Array.from(new Set(item.zones.flatMap((zone:any) => zone.regions.map((region: any) => region.region_name))));

                            const totalItems = allCountries.length + allRegions.length;

                            let displayItems = '';
                            let extra = '';

                            if (totalItems > 3) {
                                displayItems = [...allCountries, ...allRegions].slice(0, 3).join(', ');
                                extra = ` +${totalItems - 3}`;
                            } else {
                                displayItems = [...allCountries, ...allRegions].join(', ');
                            }

                            return (
                                <TableRow key={i}>
                                    <TableCell>
                                        <div className="zone_list_flex">
                                            {allCountries.length === 1 ? (
                                                <Flag countryCode={item.zones[0].country_code} svg
                                                    style={{
                                                        width: 25,
                                                        height: 19,
                                                        borderRadius: 3,
                                                        objectFit: 'cover',
                                                        marginRight: 5,
                                                    }} />
                                            ) : (
                                                <LanguageIcon style={{ marginRight: 5, color: "#1F1752" }} />
                                            )}
                                            <strong>{allCountries.join(', ')}</strong>
                                            {allRegions.length > 0 && ` - ${allRegions.slice(0, 3 - allCountries.length).join(', ')}`}
                                            {extra}
                                        </div>
                                    </TableCell>
                                    <TableCell>{item.shipping_name}</TableCell>
                                    <TableCell>
                                        {item.rates && item.rates.length > 0 ? (
                                            (() => {
                                                const minPrice = Math.min(...item.rates.map((rate: any) => rate.price));
                                                const maxPrice = Math.max(...item.rates.map((rate: any) => rate.price));
                                                
                                                if (minPrice === 0 && maxPrice === 0) return 'Free';
                                                return minPrice === maxPrice ? 
                                                    `$${minPrice}` : 
                                                    `$${minPrice} - $${maxPrice}`;
                                            })()
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label="edit" color="primary" onClick={(event) => handleMenuOpen(event, item)}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={menuAnchor}
                                            open={Boolean(menuAnchor && activeItem && activeItem.shipping_zone_id === item.shipping_zone_id)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={() => {
                                                setActiveItem(item);
                                                setOpenEditor(true);
                                                handleMenuClose();
                                            }}>
                                                <EditIcon fontSize="small" />
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={() => handleDelete(item.shipping_zone_id)}>
                                                <DeleteOutlineOutlinedIcon fontSize="small" />
                                                Remove
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>

            <ShippingZonesEditor
                setLoading={setLoading}
                openEditor={openEditor}
                setOpenEditor={setOpenEditor}
                shipping_zone_id={activeItem ? activeItem.shipping_zone_id : null}
                loadList={loadList}
            />

            <AlertDialog
                open={openAlert}
                setOpen={setOpenAlert}
                subtitle="Are you sure you want to delete this item?"
                send={deleteItem}
            />
        </div>
    )
}
